<template>
	<div id="library-wrap">
		<Header2022 :navbg='true'></Header2022>
		<div class="banner">
			<div class="subtitle">
				小院图书馆参考国内外社区图书馆发展，结合国内全民阅读的推广政策，线上开发免费借阅平台，线下以社区为核心快速布点小院图书馆，打造居民活动范围1km内的图书借阅空间场所，做全民阅读的推广者，为建设书香城市贡献自己的力量。小院图书馆提供包含“海量图书·免费借阅”服务、“心愿单”功能、共享书架、共建书屋等内容与板块。同时打造复合型阅读空间，提供包含星巴克咖啡服务、共享办公、文化沙龙等优质服务，从而传递健康、绿色、优质的生活方式。
			</div>
			<div class="content">
				<div class="item">
					<i>海量图书 免费借阅</i>
					<img src="../../assets/images/life/down1.png" >
				</div>
				<div class="item">
					<i>您的心愿单</i>
					<img src="../../assets/images/life/down2.png" >
				</div>
				<div class="item">
					<i>共享书架</i>
					<img src="../../assets/images/life/down3.png" >
				</div>
			</div>
			<el-popover placement="top" width="300" trigger="hover">
				<img src="../../assets/images/xiaochengxu-lifehall.png">
				<el-button slot="reference">立即免费借阅</el-button>
			</el-popover>
		</div>
		<div class="borrow-wrap">
			<div class="borrow">
				<div class="title">海量图书 免费借阅</div>
				<div class="intr">
					“海量图书 免费借阅”，这不仅仅是一个宣传口号，更是小院图书馆自开设以来一直在认真努力推行的事业，我们希望通过自己的一份微薄力量能够让更多大众喜欢阅读，培养大家的阅读习惯，小院图书馆也立志成为全民阅读的推广者。小院图书馆通过全网图书资源以及与新华书店、中国建筑出版社等多家合作实现图书的海量存储，并且所有图书免费借阅，我们积极践行全民阅读，为建设书香之城贡献力量。
				</div>
				<el-popover placement="top" width="300" trigger="hover">
					<img src="../../assets/images/xiaochengxu-lifehall.png">
					<el-button slot="reference" class="blackbtn">立即免费借阅</el-button>
				</el-popover>
			</div>
			<img class="borrowimg" src="../../assets/images/life/book1.png" >
		</div>
		<div class="borrow-wrap wish-wrap">
			<img class="wishimg" src="../../assets/images/life/book2.png">
			<div class="wish">
				<div class="">
					<div class="title">你的心愿单服务</div>
					<el-popover placement="top" width="300" trigger="hover">
						<img src="../../assets/images/xiaochengxu-lifehall.png">
						<el-button slot="reference" class="blackbtn">立即体验</el-button>
					</el-popover>
				</div>
				<div class="intr">
					为了满足更多读者对阅读的需要，小院图书馆设置心愿单功能，即图书馆内没有的图书，读者如果想要借阅可在小程序上提交心愿单，小院图书馆将有专属团队在72小时内将读者心愿单图书进行采购到店并联系读者取书。而图书馆内没有的图书指的是单个图书馆内当下没有读者想要借阅的图书或读者想要借阅的图书正在被借阅。以上情况下只要读者提交心愿单，我们就会在72小时之内将图书采购到店，供读者阅读。
				</div>
			</div>
		</div>
		<div class="borrow-wrap">
			<img class="borrowimg" src="../../assets/images/life/book3.png" >
			<div class="shared borrow">
				<div class="title">共享书架</div>
				<div class="intr">
					共享书架是小院图书馆业务核心服务内容，通过对阅读需求 的收集整理，为喜爱阅读的个人和家庭 准备的无限期图书借阅服务，让定量的家庭书架/个人书架成为无限阅读的共享书架。我们将推出围绕社区图书馆周边3KM社区的线上线下结合社区书屋站点，周边社区居民可在家门口享受图书的借阅服务，让阅读，尽在眼前。
					
					共享书架服务，即图书借阅会员服务，购买此服务即可享受多本图书的无限制时间借阅，会员可挑选自己喜欢的单本/多本图书进行一次性借阅，借阅时间不限，看完归还即可继续借阅喜爱的图书；会员也可选择适合自己或具有展示属性的图书进行借阅，用于充实个人书架，展示功能，借阅图书不限制归还时间，如归还，可继续借阅新的图书。
				</div>
				<el-popover placement="top" width="300" trigger="hover">
					<img src="../../assets/images/xiaochengxu-lifehall.png">
					<el-button slot="reference" class="blackbtn">详情联系我们</el-button>
				</el-popover>
			</div>
		</div>
		<Footer2022></Footer2022>
	</div>
</template>

<script>
	import utils from '@/common/utils'
	import Header2022 from '@/components/header2022.vue'
	import Footer2022 from '@/components/footer2022.vue'
	export default {
		components: {
			Header2022,
			Footer2022,	
		},
		data() {
			return {
			}
		},
		methods:{
		}
	}
</script>

<style lang="scss">
	#library-wrap{
		.el-button{
			font-size: 12px;
			text-align: center;
			cursor: pointer;
			width: 258px;
			color:#FFFFFF;
			background-color: rgba(0, 0, 0, 0);
			height: 42px;
			border: 2px solid #FFFFFF;
			border-radius: 20px;
		}
		.btn {
			font-size: 12px;
			line-height: 42px;
			text-align: center;
			cursor: pointer;
			width: 258px;
			height: 42px;
			border-radius: 20px;
		}
		.banner {
			width: 100%;
			height: 100vh;
			color: #FFFFFF;
			background: url('../../assets/images/life/bookbg.png') no-repeat;
			background-size: cover;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-around;
			.subtitle{
				font-size: 18px;
				line-height: 36px;
				width: 1439px;
				padding-top: 100px;
				text-indent: 36px;
			}
			@media only screen and (max-width:1680px){
				.subtitle{
					width: 1295px;
				}
			}
			@media only screen and (max-width:1440px){
				.subtitle{
					width: 1007px;
				}
			}
			.content{
				font-size: 40px;
				font-weight: bold;
				line-height: 40px;
				display: flex;
				justify-content: center;
				color: #FFFFFF;
				.item{
					display: flex;
					flex-direction: column;
					margin-right: 20.41%;
					// cursor: pointer;
					i{
						margin-bottom: 7px;
						text-align: center;
					}
				}
				@media only screen and (max-width:1280px){
					.item{
						margin-right: 10.41%;
					}
				}
			}
			.whitebtn{
				border: 2px solid #FFFFFF;
			}
		}
		.blackbtn{
			border: 2px solid #000000;
			color: #000000;
		}
		.title{
			font-size: 40px;
			font-weight: bold;
			line-height: 40px;
		}
		.borrow-wrap{
			display: flex;
			justify-content: center;
			color: #000000;
			font-size: 16px;
			line-height: 32px;
			width: 100%;
			.borrowimg{
				width: 75%;
			}
			.borrow{
				width: 25%;
				padding:0 50px;
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				justify-content: space-around;
				.intr{
					width: 330px;
					margin-top: -200px;
				}
			}
			.shared{
				align-items: flex-start;
				padding: 0 50px;
				.intr{
					margin-top: -120px;
				}
			}
			@media only screen and (max-width:1680px){
				.title{
					font-size: 36px;
					line-height:36px;
				}
				.borrow{
					padding: 30px;
					.intr{
						width: 300px;
						margin-top: -110px;
					}
				}
				.shared{
					.intr{
						margin-top: -10px;
					}
				}
			}
			@media only screen and (max-width:1440px){
				.title{
					font-size: 32px;
					line-height:32px;
				}
				.borrow{
					padding: 20px;
					.intr{
						width: 280px;
						font-size: 14px;
						line-height: 28px;
					}
				}
				.btn {
					line-height: 36px;
					width: 218px;
					height: 36px;
				}
			}
			@media only screen and (max-width:1366px){
				.title{
					font-size: 28px;
					line-height:28px;
				}
				.borrow{
					padding: 0 15px 0 20px;
					.intr{
						width: 260px;
						margin-top: -60px;
					}
				}
				.shared{
					.intr{
						margin-top: -30px;
					}
				}
			}
		}
		.wish-wrap{
			font-size: 16px;
			line-height: 32px;
			color: #000000;
			display: flex;
			flex-direction: column;
			width: 100%;
			.wishimg{
				width: 100%;
			}
			.wish{
				padding: 72px 17.44% 76px;
				display: flex;
				align-items: center;
				justify-content: space-around;
				.intr{
					width: 54.17%;
				}
				@media only screen and (max-width:1440px){
					.intr{
						font-size: 14px;
						line-height: 28px;
					}
				}
				.blackbtn{
					margin-top: 44px;
				}
			}
		}
	}
</style>